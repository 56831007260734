.FooterBarski {
    position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: grey;
  text-align: center;
  }

.footerBarskiLinkWrapper{
  color: white;
  line-height: 60px;
}

.footerLinksArea{
  display: flex;
}

.footerBarskiLink{
  color: white;
  text-decoration: none;
  font-weight: 700;
}

.footerBarskiLink:hover{
  color: white;
  text-decoration: none;
  text-shadow: 2px 1px #444;
}

.footerBarskiLink:visited{
  text-decoration: none;
  color: white;
}

.footerBarskiLink:focus{
  text-decoration: none;
  color: white;
}

.footLinkDivider{
  width: 20px;
  text-align: center;
}

.githubLogo{
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
}
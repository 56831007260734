.Verify {
    padding-top: 25px;
    padding-bottom: 25px;
}

.BulletTextItem_formTextInput {
    font-weight: normal;
}

.Verify-title {
    padding: 0px 20px 5px 20px;
    font-weight: 600;
    font-family: 'Bitter', serif;
    text-align: center;
}

.Verify-message {
    padding: 10px;
    text-align: center;
}

.Verify_action_button {
    padding: 15px;
}

.Verify_actions{
    text-align: center;
}
.Trips {
    padding-top: 20px;
    padding-bottom: 20px;
}

.Trips > .getTripsSection {
    padding-bottom: 35px;
}

.tripServerResults {
    color: #777;
    padding-left: 10px;
}

.tripServerResultsText {
    font-size: 12px;
}

.Trips_tripButton {
    float:left;
    margin-right:5px;
}

.Trips_tripForm {
    clear: both;
}

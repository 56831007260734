.BlogBrowser{
  background-color: #f2f2f2;
  padding-left: 30px;
}

.blogBrowserTitle{
  font-size: 50px;
  font-weight: bold;

}

.readMoreButton{
  margin-top: 6px;
}


/* https://codepen.io/ahmedbeheiry/pen/aBdezY */
.custom-btn {
  padding: 8px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  display: inline-block;
}

.btn-4 {
  width: 130px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-4 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-4:before, .btn-4:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: #000;
  transition: all 0.3s ease;
}
.btn-4:before {
  height: 50%;
  width: 2px;
}
.btn-4:after {
  width: 20%;
  height: 2px;
}
.btn-4:hover:before {height: 100%;}
.btn-4:hover:after {width: 100%;}
.btn-4 span:before, .btn-4 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: #000;
  transition: all 0.3s ease;
}
.btn-4 span:before {
  width: 2px;
  height: 50%;
}
.btn-4 span:after {
  width: 20%;
  height: 2px;
}
.btn-4 span:hover:before {height: 100%;}
.btn-4 span:hover:after {width: 100%;}

.BlogList-dateSortControls {
    font-size: 20px;
}

.Blogs {
    padding-top: 30px; 
    padding-bottom: 30px; 
    min-height: 86vh;
}

.Blogs_mobile {
    width: 95%;
}

.Blogs_error {
    padding: 45px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.Blogs_error-refresh{
    padding-left: 60px;
}

.Blogs_controls {
    text-align: end;
}

.Blogs_controls-wrapper {
    padding-left: 0;
}

.Blogs_controls-add-blog {
    padding: 10px;
}

.Blogs_controls-date {
    padding: 5px;
    font-weight: 500;
}

.BlogOneAtTime {   
    padding-top: 20px; 
}

.BlogOneAtTime-blog {
    padding-bottom: 30px; 
}

.BlogList {   
    padding-top: 20px; 
}

.navigation-icon-button {
    vertical-align: middle;
}

.Blogs_user-profile-pic-container > img {
    object-fit: cover;
    height: 100px;
    width: 100px;
}
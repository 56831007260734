.navBarLink {
    display: inline-block;
    width: 100%;
}

.brand {
    cursor: pointer;
}

.Loadingski {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.Loadingski_container {
    display: table-cell;
    vertical-align: middle;
    height: 100vh;
}

.Loadingski_container>div {
    margin-left: auto;
    margin-right: auto;
}
.BlogImagesCarousel-text-wrapper {
    background-color: black;
    min-height: 40px;
    text-align: -webkit-center;
    padding-top: 15px;
    padding-bottom: 10px;
}

.BlogImagesCarousel-text {
    color: white;
    font-size: 18px;
    max-width: 65%;
}

.BlogImagesCarousel-text-title {
    font-size: 22px;
}
.Timeline {
    min-height: 150vh;
}

.Timeline_sidenav {
    padding-top: 55px;
    position: fixed;
    top: 0vh;
    right: 0px;
    text-align: center;
}

#timeline-canvas {
    border:1px solid #d3d3d3;
}
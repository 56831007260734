.aboutmeWrapper{
  background-color: #f2f2f2;
}

.aboutMeTitle{
  padding-bottom: 15px;
  font-size: 40px;
  font-weight: bold;
}

.aboutMePargraph{
  padding-top: 10px;
}

.aboutMePargraphWithImage{
  padding-bottom: 10px;
}

.aboutMeTextSection{
  padding-bottom: 60px;
}

.aboutMeSectionTitle{
  font-weight: 600;
}
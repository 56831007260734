.BioHobbiesImg {
    padding-top: 10px;
    padding-bottom: 50px;
}

.bioHobbieDisclaimer {
    font-size: 1;
}

.Bio_emailAddress {
    color: darkblue;
    font-weight: bold;
}
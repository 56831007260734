.TitlePage {
    background-color: #bbb;
}

.TitleImage>img {
    max-width: 100%;
    max-height: none;
}

.TitlePage_aboutJeff {
    background: #EFEFEF;
}

.TitlePage_aboutJeff-image {
    padding: 20px;
}

.TitlePage_aboutJeff-text {
    display: table;
    height: 400px;
    overflow: hidden;
}

.TitlePage_aboutJeff-text-container {
    display: table-cell;
    vertical-align: middle;
}

.TitlePage_aboutJeff-image>img {
    height: 400px;
    padding: 45px;
}

.TitlePage_aboutJeff-name {
    text-align: center;
    padding-top: 50px;
}

.TitlePage_aboutJeff-name-websiteName {
    color: #000000;
    font-weight: bold;
}
.TitlePage_aboutJeff-name-nonwebsitename {
    color: #504747;
}
.loading{
  margin: 0;
    left: 50%;
}
.aboutmeWrapper{
  background-color: #f2f2f2;
}

.aboutMeTitle{
  padding-bottom: 15px;
  font-size: 40px;
  font-weight: bold;
}

.aboutMePargraph{
  padding-top: 10px;
}

.aboutMePargraphWithImage{
  padding-bottom: 10px;
}

.aboutMeTextSection{
  padding-bottom: 60px;
}

.aboutMeSectionTitle{
  font-weight: 600;
}
.BioHobbiesImg {
    padding-top: 10px;
    padding-bottom: 50px;
}

.bioHobbieDisclaimer {
    font-size: 1;
}

.Bio_emailAddress {
    color: darkblue;
    font-weight: bold;
}
.hobbiesSection{
  padding-top: 10px;
  padding-bottom: 10px;
}

.hobbiesSectionTitle{
  font-weight: bold;
}

.hobbiesSectionText {
  padding-bottom: 25px;
}

.tvshowList{
  list-style-type: none;
  margin-bottom: 0px;
}

.TvShow{
  padding-bottom: 10px; 
  overflow: hidden;  
}

.tvshowImage{
  height: 100px;
  padding: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.tvshowImageContainer{
  float: left;
  border: 1px solid gray;
  border-radius: 5px;
  min-width: 100px;
  margin-right: 15px;
}

.tvshowInfo{
  display: inline-flex;
}

.tvshowInfoContainer{
  display: inline-grid;
}

.tvshowTitle{
  font-size: medium;
  font-weight: bold;
  padding-right: 5px;
}

.tvshowText{
  font-size: medium; 
}
.Careers{
    padding: 2em; 
}

.careersTitle{
    font-weight: bold;
    font-size: 2em;
}

.careersText{
    padding-top: 1em; 
    padding-bottom: 1em; 
}
.navBarLink {
    display: inline-block;
    width: 100%;
}

.brand {
    cursor: pointer;
}

.Loadingski {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.Loadingski_container {
    display: table-cell;
    vertical-align: middle;
    height: 100vh;
}

.Loadingski_container>div {
    margin-left: auto;
    margin-right: auto;
}
.FooterBarski {
    position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: grey;
  text-align: center;
  }

.footerBarskiLinkWrapper{
  color: white;
  line-height: 60px;
}

.footerLinksArea{
  display: flex;
}

.footerBarskiLink{
  color: white;
  text-decoration: none;
  font-weight: 700;
}

.footerBarskiLink:hover{
  color: white;
  text-decoration: none;
  text-shadow: 2px 1px #444;
}

.footerBarskiLink:visited{
  text-decoration: none;
  color: white;
}

.footerBarskiLink:focus{
  text-decoration: none;
  color: white;
}

.footLinkDivider{
  width: 20px;
  text-align: center;
}

.githubLogo{
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
}
#airplane {

  /*Animation code for older Chrome, Safari, Opera*/
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 3s; /*Change to speed up or slow down the animation*/
  -webkit-animation-name: rotate; /*Call to @keyframe rule*/
  -webkit-animation-iteration-count: infinite; /*Make the animation go forever*/
  -webkit-animation-timing-function: linear;
  
  /*Animation code for older Firefox*/
  -moz-transition-property: -moz-transform;
  -moz-animation-name: moz-rotate; 
  -moz-animation-duration: 3s; 
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  
  /*Default animation code*/
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-animation-name: default-rotate;
          animation-name: default-rotate; 
  -webkit-animation-duration: 3s; 
          animation-duration: 3s; 
  animation-iteration-count: infinite; 
  animation-timing-function: linear;
}
 
/*keyframes rule for older Chrome, Safari, Opera*/
@-webkit-keyframes rotate {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
}
 
/*keyframes rule for older Firefox version*/
 
/*Default @keyframes rule*/
@-webkit-keyframes default-rotate {
    from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes default-rotate {
    from {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
}
.preloader_container { 
    display: flex;
    flex-direction: row;
    height: 100%;
}
.loading-overlayski {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: transparent;
    -webkit-transition: background-color 0.75s ease-out;
    transition: background-color 0.75s ease-out;
    z-index: 100;
}

.loading-overlayski-transition {
    background-color: grey;
}
.Login {
}

.User > div {
    margin: 0 auto;
}

.User_login-form-label {
    font-family: 'Bitter', serif;
}

.User_login-message {
    padding: 10px;
}

.User_debug-auth {
    max-width: 80%;
}
.User_reallyLongText {
    overflow: auto;
}

.Login_action_button {
    padding: 15px;
}

.Login_actions{
    text-align: center;
}
.User {
    padding-top: 20px;
    padding-bottom: 20px;
}

.User_header-title {
    font-weight: 600;
    font-family: 'Bitter', serif;
    text-align: center;
    padding: 15px;
}

/* button styling */
.User_actions-section{
    text-align: center;
}

.User_action-button {
    padding: 15px;
}

.User_error-message {
    padding: 10px;
}
.Register {
    padding-top: 25px;
    padding-bottom: 25px;
}

.BulletTextItem_formTextInput {
    font-weight: normal;
}

.Register-title {
    padding: 0px 20px 25px 20px;
    font-weight: 600;
    font-family: 'Bitter', serif;
    text-align: center;
}

.Register_validation-list {
    padding-left: 0;
    list-style: none;
}

.Register_password-valid {
    color: green;
    font-weight: bold;
}

.Register_password-valid:before {
    content: '\2713   ';
}

.Register_password-invalid {
    color: rgb(44, 44, 44);
}

.Register_password-invalid:before {
    content: '\2717   ';
}

.react-datepicker-popper {
    z-index: 10;
}


.Verify {
    padding-top: 25px;
    padding-bottom: 25px;
}

.BulletTextItem_formTextInput {
    font-weight: normal;
}

.Verify-title {
    padding: 0px 20px 5px 20px;
    font-weight: 600;
    font-family: 'Bitter', serif;
    text-align: center;
}

.Verify-message {
    padding: 10px;
    text-align: center;
}

.Verify_action_button {
    padding: 15px;
}

.Verify_actions{
    text-align: center;
}
.Profile_title {
    font-size: 1.5em;
    font-weight: 600; 
}

.Profile_header {
    font-weight: bold;
}

.Profile_bio-trip-row {
    padding-top: 25px;
    padding-bottom: 25px;
}

.Profile_bio {
    font-family: 'Bitter', serif;
    padding-bottom: 25px;
}

.Profile_actions {
    padding: 25px;
}

.Profile_profilepic {
    cursor: pointer;
}
.Profile_profilepic > img {
    margin-left: 0;
}

.Profile_empty-info {
    color: rgb(70, 70, 70);
}

.Profile_bio-show-more-less > button {
    padding: 0 0 0 10px;
}

.User_action-button-login-area {
    text-align: right;
}

.Profile_trip-section-addTripButton {
    padding-left: 10px;
}

.Profile_trip-section-addTripForm {
    padding-top: 10px;
    padding-bottom: 0px;
}

.Profile_trip-section-addTripErrorMesssage {
    padding-top: 0px;
    padding-bottom: 10px;
    font-size: 14px;
    color: red;
}
.BlogImagesCarousel-text-wrapper {
    background-color: black;
    min-height: 40px;
    text-align: -webkit-center;
    padding-top: 15px;
    padding-bottom: 10px;
}

.BlogImagesCarousel-text {
    color: white;
    font-size: 18px;
    max-width: 65%;
}

.BlogImagesCarousel-text-title {
    font-size: 22px;
}
img {
    max-width: 1000px;
    max-height: 600px;
    height: auto;
    width: auto;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.image-gallery-image {
    background-color: black;
}

.image-gallery-image > img {
    min-height: 600px;
}

.BlogImages-mobile-responsive-images {
    border: solid lightgrey;
    border-radius: 10px;
    margin-bottom: 20px;
}

.BlogImages-mobile-responsive-images img {
    padding: 10px;
    max-height: 400px;
}

.BlogImages-top-spacer {
    height: 50px;
}

.BlogImages-mobile-text-container {
    padding: 15px;
}

.BlogImages-mobile-text {
    word-break: break-word;
}
.material-icons.successColor { 
    color: rgb(62, 219, 0); 
}

.material-icons.failureColor { 
    color: rgb(255, 51, 0); 
}

.statusArea {
    display: inline-block;
    padding-left: 10px;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
}

.statusAreaVisible {
    opacity: 1;
}

.statusAreaHidden {
    opacity: 0;
}
.ResizeProfileImg_progressIndicator {
    text-align: center;
}
.BlogDate_time-of-day-dropdown {
    display: inline;
}

.BlogDate_time-of-day {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ImageForm__preview-image {
    padding: 10px 5px 10px 5px;
}

.ImageForm__preview-image-individual {
    height: 300px;
}

.ImageForm__preview-image-details {
    padding-top: 5px;
    padding-bottom: 5px;
}

.ImageForm__preview-image-file-name {
    padding-bottom: 5px;
}

.ImageForm__file_size {
    padding-left: 15px;
}

.ImageForm__file_size_valid {
    color: green;
}

.ImageForm__file_size_invalid {
    color: red;
}

.ImageForm__file_details {
    display: flex;
}
.AddBlog_loading > div {
    margin: auto;
}
.BlogPage {
    margin-bottom: 50px;
}

.blogPargraph{
  margin-top: 10px;
}

.BlogPage__first-paragraph {
    padding-bottom: 10px;
}

.BlogPage_loadingImage {
    visibility: hidden;
    height: 0;
}
.Timeline {
    min-height: 150vh;
}

.Timeline_sidenav {
    padding-top: 55px;
    position: fixed;
    top: 0vh;
    right: 0px;
    text-align: center;
}

#timeline-canvas {
    border:1px solid #d3d3d3;
}
.Blogs_tripTitleEditButton {
    padding: 10px;
}

.Blogs_trip-title-edit {
    font-size: larger;
}
.BlogBrowser{
  background-color: #f2f2f2;
  padding-left: 30px;
}

.blogBrowserTitle{
  font-size: 50px;
  font-weight: bold;

}

.readMoreButton{
  margin-top: 6px;
}


/* https://codepen.io/ahmedbeheiry/pen/aBdezY */
.custom-btn {
  padding: 8px 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: relative;
  display: inline-block;
}

.btn-4 {
  width: 130px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-4 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-4:before, .btn-4:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-4:before {
  height: 50%;
  width: 2px;
}
.btn-4:after {
  width: 20%;
  height: 2px;
}
.btn-4:hover:before {height: 100%;}
.btn-4:hover:after {width: 100%;}
.btn-4 span:before, .btn-4 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-4 span:before {
  width: 2px;
  height: 50%;
}
.btn-4 span:after {
  width: 20%;
  height: 2px;
}
.btn-4 span:hover:before {height: 100%;}
.btn-4 span:hover:after {width: 100%;}

.BlogList-dateSortControls {
    font-size: 20px;
}

.Blogs {
    padding-top: 30px; 
    padding-bottom: 30px; 
    min-height: 86vh;
}

.Blogs_mobile {
    width: 95%;
}

.Blogs_error {
    padding: 45px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.Blogs_error-refresh{
    padding-left: 60px;
}

.Blogs_controls {
    text-align: end;
}

.Blogs_controls-wrapper {
    padding-left: 0;
}

.Blogs_controls-add-blog {
    padding: 10px;
}

.Blogs_controls-date {
    padding: 5px;
    font-weight: 500;
}

.BlogOneAtTime {   
    padding-top: 20px; 
}

.BlogOneAtTime-blog {
    padding-bottom: 30px; 
}

.BlogList {   
    padding-top: 20px; 
}

.navigation-icon-button {
    vertical-align: middle;
}

.Blogs_user-profile-pic-container > img {
    object-fit: cover;
    height: 100px;
    width: 100px;
}
.ProfileEditInfo_profilepic {
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    text-align: center;
}
#travelTrailsHome {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#000000b3), to(#000000b3)),url(https://s3.us-east-2.amazonaws.com/jeff.ski/traveltrail/home/traveltrail_home_md.jpg);
    background-image: -webkit-linear-gradient(#000000b3, #000000b3),url(https://s3.us-east-2.amazonaws.com/jeff.ski/traveltrail/home/traveltrail_home_md.jpg);
    background-image: linear-gradient(#000000b3, #000000b3),url(https://s3.us-east-2.amazonaws.com/jeff.ski/traveltrail/home/traveltrail_home_md.jpg);
    background-size: cover;
    background-position: center;
    height: -webkit-fill-available;
}

.TravelTrailsHome_imageContainer > img {
    max-width: 100%;
    max-height: none;
}

.TravelTrailsHome_title {
    font-family: 'Bitter', serif;
    color: whitesmoke;
    font-size: 500%;
    margin-top: 0;
    margin-bottom: 15px;
    word-spacing: 3px;
    font-weight: bold;
}

.TravelTrailsHome_titleContainer {
    position: absolute;
    top: 50%;
    left: 35%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.TravelTrail_button:link { 
    display: inline-block;
    font-weight: 500;
    font-size: 140%;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 1px;
    -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.TravelTrail_button-full {
    color: black;
    background-color: whitesmoke;
    border: 1px solid #fff;
}

.TravelTrail_button-full:hover,
.TravelTrail_button-full:active {
    background-color: #d1d1d1;
    color: black;
}

.TravelTrail_button-ghost {
    color: antiquewhite;
    border: 2px solid;
}

.TravelTrail_button-ghost:hover,
.TravelTrail_button-ghost:active {
    color: #fff7f4;
    background-color: rgba(255, 179, 145, 0.51);
}

#travelTrailHome-buttonLeft {
    margin-right: 20px;
}

.TravelTrailers_mobile-text h1 {
    font-size: 300%;
    color: white;
}

.TravelTrailers_mobile > img {
    -webkit-filter: grayscale(0%) contrast(88%) brightness(28%);
            filter: grayscale(0%) contrast(88%) brightness(28%);
}

.TravelTrailers_desktop > img {
    -webkit-filter: grayscale(0%) contrast(88%) brightness(28%);
            filter: grayscale(0%) contrast(88%) brightness(28%);
    max-width: 2000px;
    max-height: 1000px;
    margin: 0px
}

.TravelTrailsHome_container {
    padding: 0;
}

.TravelTrailsHome_aboutTitle {
    text-align: center;
    font-family: 'Bitter', serif;
    color: rgb(15, 15, 15);
    font-weight: bold;
    padding: 50px;
}

.TravelTrailsHome_about-imagetext{
    text-align: center;
    font-size: 50%;
}

.TravelTrailsHome_about-text-container {
    display: table-cell;
    vertical-align: middle;
}

.TravelTrailsHome_about-text {
    padding: 50px;
    display: table;
    overflow: hidden;
    height: 600px;
}

.TravelTrailsHome_about-image {
    padding: 50px;
}

.TravelTrailsHome_about-image > img {
    -webkit-filter: grayscale(32%) contrast(100%) brightness(45%);
            filter: grayscale(32%) contrast(100%) brightness(45%)
}


.TripsDropdown {
    padding-bottom: 35px;
}

.existingTrips > .tripInformation {
    padding-top: 20px;
    padding-bottom: 20px;
}

.tripServerResults {
    color: #777;
    padding-left: 10px;
}

.tripServerResultsText {
    font-size: 12px;
}
/* make month dropdown take up more space */
#TripForm_monthFormGroup > div {
    display: grid;   
}
.Trips {
    padding-top: 20px;
    padding-bottom: 20px;
}

.Trips > .getTripsSection {
    padding-bottom: 35px;
}

.tripServerResults {
    color: #777;
    padding-left: 10px;
}

.tripServerResultsText {
    font-size: 12px;
}

.Trips_tripButton {
    float:left;
    margin-right:5px;
}

.Trips_tripForm {
    clear: both;
}

.webpagecontent {
    padding-top: 55px;
    padding-bottom: 55px;
}

i {
    vertical-align: sub;
}

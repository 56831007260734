.User {
    padding-top: 20px;
    padding-bottom: 20px;
}

.User_header-title {
    font-weight: 600;
    font-family: 'Bitter', serif;
    text-align: center;
    padding: 15px;
}

/* button styling */
.User_actions-section{
    text-align: center;
}

.User_action-button {
    padding: 15px;
}

.User_error-message {
    padding: 10px;
}
.Profile_title {
    font-size: 1.5em;
    font-weight: 600; 
}

.Profile_header {
    font-weight: bold;
}

.Profile_bio-trip-row {
    padding-top: 25px;
    padding-bottom: 25px;
}

.Profile_bio {
    font-family: 'Bitter', serif;
    padding-bottom: 25px;
}

.Profile_actions {
    padding: 25px;
}

.Profile_profilepic {
    cursor: pointer;
}
.Profile_profilepic > img {
    margin-left: 0;
}

.Profile_empty-info {
    color: rgb(70, 70, 70);
}

.Profile_bio-show-more-less > button {
    padding: 0 0 0 10px;
}

.User_action-button-login-area {
    text-align: right;
}

.Profile_trip-section-addTripButton {
    padding-left: 10px;
}

.Profile_trip-section-addTripForm {
    padding-top: 10px;
    padding-bottom: 0px;
}

.Profile_trip-section-addTripErrorMesssage {
    padding-top: 0px;
    padding-bottom: 10px;
    font-size: 14px;
    color: red;
}
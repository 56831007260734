img {
    max-width: 1000px;
    max-height: 600px;
    height: auto;
    width: auto;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.image-gallery-image {
    background-color: black;
}

.image-gallery-image > img {
    min-height: 600px;
}

.BlogImages-mobile-responsive-images {
    border: solid lightgrey;
    border-radius: 10px;
    margin-bottom: 20px;
}

.BlogImages-mobile-responsive-images img {
    padding: 10px;
    max-height: 400px;
}

.BlogImages-top-spacer {
    height: 50px;
}

.BlogImages-mobile-text-container {
    padding: 15px;
}

.BlogImages-mobile-text {
    word-break: break-word;
}
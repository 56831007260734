.loading-overlayski {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: transparent;
    -webkit-transition: background-color 0.75s ease-out;
    -moz-transition: background-color 0.75s ease-out;
    -o-transition: background-color 0.75s ease-out;
    transition: background-color 0.75s ease-out;
    z-index: 100;
}

.loading-overlayski-transition {
    background-color: grey;
}
.Register {
    padding-top: 25px;
    padding-bottom: 25px;
}

.BulletTextItem_formTextInput {
    font-weight: normal;
}

.Register-title {
    padding: 0px 20px 25px 20px;
    font-weight: 600;
    font-family: 'Bitter', serif;
    text-align: center;
}

.Register_validation-list {
    padding-left: 0;
    list-style: none;
}

.Register_password-valid {
    color: green;
    font-weight: bold;
}

.Register_password-valid:before {
    content: '✓ ';
}

.Register_password-invalid {
    color: rgb(44, 44, 44);
}

.Register_password-invalid:before {
    content: '✗ ';
}

.react-datepicker-popper {
    z-index: 10;
}


#travelTrailsHome {
    background-image: linear-gradient(#000000b3, #000000b3),url(https://s3.us-east-2.amazonaws.com/jeff.ski/traveltrail/home/traveltrail_home_md.jpg);
    background-size: cover;
    background-position: center;
    height: -webkit-fill-available;
}

.TravelTrailsHome_imageContainer > img {
    max-width: 100%;
    max-height: none;
}

.TravelTrailsHome_title {
    font-family: 'Bitter', serif;
    color: whitesmoke;
    font-size: 500%;
    margin-top: 0;
    margin-bottom: 15px;
    word-spacing: 3px;
    font-weight: bold;
}

.TravelTrailsHome_titleContainer {
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
}

.TravelTrail_button:link { 
    display: inline-block;
    font-weight: 500;
    font-size: 140%;
    padding: 5px 10px;
    text-decoration: none;
    border-radius: 1px;
    transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.TravelTrail_button-full {
    color: black;
    background-color: whitesmoke;
    border: 1px solid #fff;
}

.TravelTrail_button-full:hover,
.TravelTrail_button-full:active {
    background-color: #d1d1d1;
    color: black;
}

.TravelTrail_button-ghost {
    color: antiquewhite;
    border: 2px solid;
}

.TravelTrail_button-ghost:hover,
.TravelTrail_button-ghost:active {
    color: #fff7f4;
    background-color: rgba(255, 179, 145, 0.51);
}

#travelTrailHome-buttonLeft {
    margin-right: 20px;
}

.TravelTrailers_mobile-text h1 {
    font-size: 300%;
    color: white;
}

.TravelTrailers_mobile > img {
    filter: grayscale(0%) contrast(88%) brightness(28%);
}

.TravelTrailers_desktop > img {
    filter: grayscale(0%) contrast(88%) brightness(28%);
    max-width: 2000px;
    max-height: 1000px;
    margin: 0px
}

.TravelTrailsHome_container {
    padding: 0;
}

.TravelTrailsHome_aboutTitle {
    text-align: center;
    font-family: 'Bitter', serif;
    color: rgb(15, 15, 15);
    font-weight: bold;
    padding: 50px;
}

.TravelTrailsHome_about-imagetext{
    text-align: center;
    font-size: 50%;
}

.TravelTrailsHome_about-text-container {
    display: table-cell;
    vertical-align: middle;
}

.TravelTrailsHome_about-text {
    padding: 50px;
    display: table;
    overflow: hidden;
    height: 600px;
}

.TravelTrailsHome_about-image {
    padding: 50px;
}

.TravelTrailsHome_about-image > img {
    filter: grayscale(32%) contrast(100%) brightness(45%)
}
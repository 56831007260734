

.TripsDropdown {
    padding-bottom: 35px;
}

.existingTrips > .tripInformation {
    padding-top: 20px;
    padding-bottom: 20px;
}

.tripServerResults {
    color: #777;
    padding-left: 10px;
}

.tripServerResultsText {
    font-size: 12px;
}
#airplane {

  /*Animation code for older Chrome, Safari, Opera*/
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 3s; /*Change to speed up or slow down the animation*/
  -webkit-animation-name: rotate; /*Call to @keyframe rule*/
  -webkit-animation-iteration-count: infinite; /*Make the animation go forever*/
  -webkit-animation-timing-function: linear;
  
  /*Animation code for older Firefox*/
  -moz-transition-property: -moz-transform;
  -moz-animation-name: moz-rotate; 
  -moz-animation-duration: 3s; 
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  
  /*Default animation code*/
  transition-property: transform;
  animation-name: default-rotate; 
  animation-duration: 3s; 
  animation-iteration-count: infinite; 
  animation-timing-function: linear;
}
 
/*keyframes rule for older Chrome, Safari, Opera*/
@-webkit-keyframes rotate {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
}
 
/*keyframes rule for older Firefox version*/
@-moz-keyframes moz-rotate {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(360deg);}
}
 
/*Default @keyframes rule*/
@keyframes default-rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
}
.preloader_container { 
    display: flex;
    flex-direction: row;
    height: 100%;
}
.Login {
}

.User > div {
    margin: 0 auto;
}

.User_login-form-label {
    font-family: 'Bitter', serif;
}

.User_login-message {
    padding: 10px;
}

.User_debug-auth {
    max-width: 80%;
}
.User_reallyLongText {
    overflow: auto;
}

.Login_action_button {
    padding: 15px;
}

.Login_actions{
    text-align: center;
}
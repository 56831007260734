.hobbiesSection{
  padding-top: 10px;
  padding-bottom: 10px;
}

.hobbiesSectionTitle{
  font-weight: bold;
}

.hobbiesSectionText {
  padding-bottom: 25px;
}

.tvshowList{
  list-style-type: none;
  margin-bottom: 0px;
}

.TvShow{
  padding-bottom: 10px; 
  overflow: hidden;  
}

.tvshowImage{
  height: 100px;
  padding: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.tvshowImageContainer{
  float: left;
  border: 1px solid gray;
  border-radius: 5px;
  min-width: 100px;
  margin-right: 15px;
}

.tvshowInfo{
  display: inline-flex;
}

.tvshowInfoContainer{
  display: inline-grid;
}

.tvshowTitle{
  font-size: medium;
  font-weight: bold;
  padding-right: 5px;
}

.tvshowText{
  font-size: medium; 
}
.BlogPage {
    margin-bottom: 50px;
}

.blogPargraph{
  margin-top: 10px;
}

.BlogPage__first-paragraph {
    padding-bottom: 10px;
}

.BlogPage_loadingImage {
    visibility: hidden;
    height: 0;
}